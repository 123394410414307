import { Typography, Stack, Button } from '@mui/material'
import { ModalView } from '../../Shared'
import QRCode from 'react-qr-code'

export default function ShowGiveCutOff({ displayState, ticket }) {
  const [show, setShow] = displayState
  return (
    <ModalView
      displayState={[show, setShow]}
      width={'30%'}
      maxHeight={'70%'}
    >
      <Stack spacing={2}>
        <Typography variant='h3'>Escanea el QR desde la aplicación para recibir el corte.</Typography>
        <Stack
          justifyContent='center'
          alignItems='center'
        >
          <div style={{ background: 'white', padding: '16px' }}>
            <QRCode
              value={`${ticket}`}
              level='H'
            />
          </div>
        </Stack>
      </Stack>
      <Stack
        marginTop={1}
        justifyContent='flex-end'
        alignItems='flex-end'
      >
        <Button onClick={() => setShow(false)}>Cerrar</Button>
      </Stack>
    </ModalView>
  )
}
