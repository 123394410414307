/* Importaciones de React */
import React, { useState, useEffect, useContext } from 'react'

/* Importaciones de @emotion/react */
import { useTheme } from '@emotion/react'

/* Importaciones de MUI */
import { Stack, TextField, InputAdornment, Typography } from '@mui/material'
import { Search, AirOutlined, PendingActionsOutlined, Replay, SearchOff } from '@mui/icons-material'

/* Importaciones de React-Icons */
import { LuServerOff } from 'react-icons/lu'

/* Importaciones del modulo de Shared */
import { Message, SectionCard, SkeletonLoad, Table } from '../../Shared'

/* Importaciones de elementos compartidos del modulo de mi carrito */
import { PendingPay } from './ShoppingCartSharedComponents'

/* Importación del context del modulo de ShoppingCart */
import ShoppingCartContext from './ShoppingCartContext'

export default function PendingPaysList({ addPendingPayHandler }){
    /* Variables */
    const { carts, actualCart } = useContext(ShoppingCartContext)
    const cart = carts[actualCart]

    /* Estados */
    const [ UUID, setUUID ] = useState('')
    const [ loadingPendingPays, setLoadingPendingPays ] = useState(true)
    const [ pendingPays, setPendingPays ] = useState(null)
    const [ pendingPaysFoundAt, setPendingPaysFoundAt ] = useState(null)
    const [ pendingPaysMessageTitle, setPendingPaysMessageTitle ] = useState('')
    const [ pendingPaysMessageContent, setPendingPaysMessageContent ] = useState('')

    /* Funciones */
    const searchingPendingPays = () => {
        setPendingPaysFoundAt(pendingPays?.filter(pendingPay => (new RegExp(UUID, 'i').test(pendingPay?.uuid)) || (new RegExp(UUID, 'i').test(pendingPay?.empresa.nombre_comercial)) || UUID === ''))
    }

    /* Funciones asincronas */
    const getPendingPays = async () => {
        /* Sub-funciones */
        const addingPendingPays = data => { 
            const sessions = JSON.parse(localStorage.getItem('sessions'))
            sessions[(JSON.parse(sessionStorage.getItem('user'))).id]['pendingPays'] = data
            localStorage.setItem('sessions', JSON.stringify(sessions))
        }

        try {
            setLoadingPendingPays(true)

            const request = await fetch(`${sessionStorage.getItem('localhost')}/api/v2/tickets?pendingPays=${JSON.stringify(cart?.pendingPays?.length > 0 ? cart?.pendingPays?.map(pendingPay => pendingPay?.uuid) : [])}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Cache-Control': 'no-cache'
                }
            })
    
            if(request?.ok){
                const response = await request.json()
                setPendingPays(response?.pendingPays)
                setPendingPaysFoundAt(response?.pendingPays)
                addingPendingPays(response?.pendingPays)
                if(response?.pendingPays?.lenght === 0 ){
                    setPendingPaysMessageTitle('Sin pagos pendientes')
                    setPendingPaysMessageContent('Para poder visualizar los pagos pendientes, realice un pago pendiente o agregue articulos en un carrito.')
                }
                return
            }
    
            throw new Error('Hubo un problema con el servidor-Ocurrio un problema al momento de traer los pagos pendientes, intentelo más tarde.')
        } catch (error) {
            const requestErrorMessage = error.message.split('-')
            setPendingPaysMessageTitle(requestErrorMessage[0])
            setPendingPaysMessageContent(requestErrorMessage[1])
            setPendingPays(null)
            // addingPendingPays(null)
        } finally {
            setLoadingPendingPays(false)
        }
    }

    /* useEffects */
    useEffect(() => getPendingPays(), [])
    useEffect(() => searchingPendingPays(), [UUID])

    /* Componente */
    return (
        <Stack
            padding='5px 0px 0px 0px'
            direction='column'
            spacing={0.5}
        >   
            {loadingPendingPays && (
                <Stack
                    width={552}
                    height={292.5}
                    direction='column'
                    spacing={0.5}
                >
                    <SkeletonLoad 
                        types={['rectangular']}
                        numberOfItems={1}
                        randomHeight
                        heightRange={[53.1333, 53.1333]}
                    />

                    <Stack
                        direction='column'
                        padding='3px'
                        width={552}
                        height={230.5}
                    >
                        <SkeletonLoad 
                            types={['rectangular']}
                            numberOfItems={3}
                            randomHeight
                            heightRange={[61.5, 61.5]}
                        />
                    </Stack>
                </Stack>
            )}

            {!loadingPendingPays && (pendingPays === null || pendingPays?.length === 0) && (
                <Stack
                    width={600}
                    height={365.133}
                    direction='column'
                    justifyContent='center'
                >
                    <Message
                        title={pendingPaysMessageTitle}
                        message={pendingPaysMessageContent}
                        icon={pendingPays === null ? <LuServerOff fontSize={50} /> : <AirOutlined sx={{ fontSize: 50 }} />}
                        callback={pendingPays === null ? () => {} : () => {}}
                        buttonMessage={
                            pendingPays === null ? 
                                <Stack
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='center'
                                    gap={2}
                                    color='#ffffff'
                                    fontWeight='600'
                                    border='2px solid #ff4500'
                                    borderRadius={3}
                                    bgcolor='#ff4500'
                                    paddingY={0.3}
                                    paddingX={1}
                                    sx={{
                                        '&:hover': {
                                            color: '#dddddd',
                                            border: '2px solid #d53a00',
                                            bgcolor: '#d53a00'
                                        }
                                    }}
                                >
                                    <Replay />

                                    <Typography
                                        fontSize={15}
                                    >
                                        Volver intentar
                                    </Typography>
                                </Stack>
                            : 
                                <Stack
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='center'
                                    gap={2}
                                    color='#ffffff'
                                    fontWeight='600'
                                    border='2px solid #ff4500'
                                    borderRadius={3}
                                    bgcolor='#ff4500'
                                    paddingY={0.3}
                                    paddingX={1}
                                    sx={{
                                        '&:hover': {
                                            color: '#dddddd',
                                            border: '2px solid #d53a00',
                                            bgcolor: '#d53a00'
                                        }
                                    }}
                                >
                                    <PendingActionsOutlined />

                                    <Typography
                                        fontSize={15}
                                    >
                                        Completar pago pendiente
                                    </Typography>
                                </Stack>
                        }
                    />
                </Stack>
            )}

            {!loadingPendingPays && pendingPays?.length > 0 && <>
                <TextField 
                    variant='outlined'
                    label='UUID del ticket'
                    placeholder='Introduce el UUID de su ticket para localizar su pago pendiente.'
                    value={UUID}
                    onChange={e => setUUID(e.target.value.replace(/[^a-zA-Z0-9- ]/g, ''))}
                    focused
                    InputProps={{
                        startAdornment: <InputAdornment position='start'><Search /></InputAdornment>
                    }}
                    fullWidth
                />

                {pendingPaysFoundAt?.length > 0 && (
                    <Stack
                        direction='column'
                        spacing={1}
                        padding='3px'
                        sx={{
                            height: 230.5,
                            overflowX: 'hidden',
                            overflowY: 'auto'
                        }}
                    >
                        {pendingPaysFoundAt.map(pendingPay => ( 
                            <PendingPay 
                                pendingPay={pendingPay}
                                addPendingPayHandler={addPendingPayHandler} 
                            /> 
                        ))}
                    </Stack>
                )}

                {pendingPaysFoundAt?.length === 0 && (
                    <Stack
                        width={552}
                        height={230.5}
                        direction='column'
                        justifyContent='center'
                    >
                        <Message 
                            title='Sin resultados'
                            message='Revise los datos de su ticket(uuid ó compania) para localizar los tickets que se ocupan.'
                            icon={<SearchOff sx={{ fontSize: 50 }} />}
                        />
                    </Stack>
                )}
            </>}
        </Stack>
    )
}