import { useState, useEffect } from 'react'
import { TextField, Button, Alert, Typography, Divider, Stack, InputAdornment } from '@mui/material'
import { createExpense } from './Services'
import { ModalView } from '../../Shared'

export default function ExpenseForm({ displayState, callback }) {
  const token = sessionStorage.getItem('token')
  const host = sessionStorage.getItem('localhost')
  const [show, setShow] = displayState
  const [price, setPrice] = useState()
  const [details, setDetails] = useState('')
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [message, setMessage] = useState('')

  const validateNumber = s => /^\d{0,8}(\.\d{0,2})?$/.test(s)

  async function handleCreateExpense() {
    try {
      setLoading(true)
      const { error, message } = await createExpense({ host, token, details, price })
      if (error) {
        if (message) setMessage(message)
        setShowError(true)
      } else {
        setShow(false)
        callback()
      }
    } catch (error) {
      setShowError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    return () => {
      setPrice()
      setDetails('')
    }
  }, [show])

  useEffect(() => {
    if (showError) setTimeout(() => setShowError(false), 6000)
  }, [showError])

  return (
    <ModalView
      displayState={displayState}
      width={'30%'}
      maxHeight={'70%'}
    >
      <Typography
        variant='h1'
        paddingY={1}
      >
        Añadir un gasto
      </Typography>
      <Divider sx={{ marginBottom: 1 }} />
      <Stack spacing={2}>
        <TextField
          label='Precio'
          variant='outlined'
          value={price}
          onChange={({ target }) => validateNumber(target.value) && setPrice(target.value)}
          InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
        />
        <TextField
          label='Detalles'
          variant='outlined'
          value={details}
          multiline
          rows={4}
          onChange={e => setDetails(e.target.value)}
        />
        {showError && <Alert severity='error'>{message ?? 'Ocurrió un error, intente más tarde.'}</Alert>}
      </Stack>
      <Stack
        marginTop={2}
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-end'
      >
        <Button
          disabled={loading || price === '' || price === undefined || !details}
          onClick={() => handleCreateExpense()}
        >
          Crear gasto
        </Button>
        <Button
          disabled={loading}
          onClick={() => setShow(false)}
        >
          Cerrar
        </Button>
      </Stack>
    </ModalView>
  )
}
