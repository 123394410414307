import './App.css'

import NavHeader from './NavHeader/Dashboard'

import LogInIndex from './Modules/LogIn/index'

import Profile from './Modules/Profile/Index'
import BuyCredit from './Modules/CreditMovements/Index'
import GuiasIndex from './Modules/GuiasAdmin/ReceiveGuias/index'
import FormTracking from './Modules/GuiasAdmin/Tracking/index'
import ReportsIndex from './Modules/GuiasAdmin/Reports/index'
import ShippingIndex from './Modules/GuiasAdmin/Shipping/index'
import ShippingInfoList from './Modules/GuiasAdmin/Shipping/shippingInfoList'
import ShippingInsurance from './Modules/GuiasAdmin/Shipping/shippingInsurance'
import OriginOrDestination from './Modules/GuiasAdmin/Shipping/originOrDestination'
import PaymentConfirmation from './Modules/GuiasAdmin/Shipping/paymentConfirmation'
import WaybillsTable from './Modules/GuiasAdmin/Shipping/waybillsTable'
import AdminAddress from './Modules/AdminDirecciones/Index'
import DownloadAndUploadTemplate from './Modules/GuiasAdmin/Shipping/downloadAndUploadTemplate'
import Prepagos from './Modules/Prepagos/Index'
import MyShoppingCart from './Modules/MyShoppingCart/Index'
import ListUsers from './Modules/Users'
import ListServices from './Modules/Services'
// import MethodsOfPayment from './Modules/GuiasAdmin/Shipping/methodsOfPayment';
import FormularioDeCotizaciones from './Modules/Cotizaciones/Index';

import Support from './Modules/Support/Index';
import VideoTutorial from './Modules/Support/Index';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useState, useMemo, useEffect } from 'react'
import ListCutOffs from './Modules/CutOffs/Index'
import CutOffItems from './Modules/CutOffs/CutOffItems'

export default function App() {
  const userPermissions = JSON.parse(sessionStorage.getItem('permissions'))
  const [prefersDarkMode, setPrefersDarkMode] = useState(false)
  const token = sessionStorage.getItem('token')
  const user = sessionStorage.getItem('user')
  const theme = useMemo(() => {
    return createTheme({
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
        primary: {
          main: '#ff5722'
        },
        secondary: {
          main: '#66bb6a'
        },
        error: {
          main: '#e53935'
        },
        success: {
          main: '#66bb6a'
        },
        warning: {
          main: '#ffa726'
        },
        info: {
          main: '#29b6f6'
        }
      },
      typography: {
        fontFamily: 'Lexend Deca',
        fontSize: 14,
        h1: {
          fontSize: 24,
          fontWeight: 1000,
          lineHeight: 1
        },
        h2: {
          fontSize: 20,
          fontWeight: 1000,
          lineHeight: 1
        },
        h3: {
          fontSize: 14,
          fontWeight: 1000,
          lineHeight: 1
        },
        h4: {
          fontSize: 12
        },
        caption: {
          fontSize: 10,
          fontWeight: 1000,
          lineHeight: 1
        },
        body1: {
          fontSize: 14,
          lineHeight: 1.3,
          fontWeight: 300
        },
        body2: {
          fontSize: 14,
          fontWeight: 300,
          lineHeight: 1.3
        },
        emphasis: {
          fontSize: 30,
          fontWeight: 300,
          lineHeight: 1.3
        },
        button: {
          fontSize: 14
        },
        subtitle1: {
          fontSize: 16,
          fontWeight: 1000
        },
        subtitle2: {
          fontSize: 12
        }
      }
    })
  }, [prefersDarkMode])

  const routes = [
    userPermissions !== null &&
      userPermissions['reports-access'] !== undefined && {
        url: '/',
        module: (
          <GuiasIndex
            locationId={1}
            location=''
          />
        )
      },
    {
      url: '/MyShoppingCart',
      module: <MyShoppingCart />
    },
    // userPermissions !== null &&
    //   userPermissions['quotes-access'] !== undefined && {
    //     url: '/quotes',
    //     module: <FormularioDeCotizaciones />
    //   },
    {
      url: '/profile',
      module: <Profile />
    },
    {
      url: '/addCredits',
      module: <BuyCredit />
    },
    userPermissions !== null &&
      userPermissions['waybills-access'] !== undefined && {
        url: '/waybillsTable',
        module: <WaybillsTable />
      },
    userPermissions !== null &&
      userPermissions['waybills-access'] !== undefined && {
        url: '/massiveWaybills',
        module: <DownloadAndUploadTemplate />
      },
    userPermissions !== null &&
      userPermissions['waybills-access'] !== undefined && {
        url: '/shipping',
        module: <ShippingIndex />
      },
    userPermissions !== null &&
      userPermissions['waybills-access'] !== undefined && {
        url: '/shippingInfoList',
        module: <ShippingInfoList />
      },
    userPermissions !== null &&
      userPermissions['waybills-access'] !== undefined && {
        url: '/shippingInsurance',
        module: <ShippingInsurance />
      },
    // ( userPermissions !== null && userPermissions["waybills-access"] !== undefined ) &&
    //     {
    //         url:"/methodsOfPayment",
    //         module:<MethodsOfPayment />
    //     } : null,
    userPermissions !== null &&
      userPermissions['waybills-access'] !== undefined && {
        url: '/originOrDestination',
        module: <OriginOrDestination />
      },
    userPermissions !== null &&
      userPermissions['waybills-access'] !== undefined && {
        url: '/paymentConfirmation',
        module: <PaymentConfirmation />
      },
    userPermissions !== null &&
      userPermissions['tracking-access'] !== undefined && {
        url: '/tracking',
        module: <FormTracking />
      },
    userPermissions !== null &&
      userPermissions['sucursal-access'] !== undefined && {
        url: '/addWaybills',
        module: <GuiasIndex />
      },
    userPermissions !== null &&
      userPermissions['address-access'] !== undefined && {
        url: '/adminAddress',
        module: <AdminAddress />
      },
    userPermissions !== null &&
      userPermissions['reports-access'] !== undefined && {
        url: '/reports',
        module: <ReportsIndex />
      },
    userPermissions !== null &&
      userPermissions['role'] !== 'Cliente' && {
        url: '/cut-offs',
        module: <ListCutOffs />
      },
    userPermissions !== null &&
      userPermissions['role'] !== 'Cliente' && {
        url: '/cut-offs/items',
        module: <CutOffItems />
      },
    // {
    //   url: '/prepagos',
    //   module: <Prepagos />
    // },
    userPermissions !== null &&
      userPermissions['users-access'] !== undefined && {
        url: '/users',
        module: <ListUsers />
      },
    userPermissions !== null &&
      userPermissions['services-access'] !== undefined && {
        url: '/users/services',
        module: <ListServices />
      },
      userPermissions !== null &&
        userPermissions["role"] !== "Cliente" && {
          url: '/cut-offs/items',
          module: <CutOffItems/>
        },
        {
          url: '/support',
          module: <VideoTutorial/>
        }
  ].filter(item => item)

  const modules = routes.map((item, key) => (
    <Route
      key={key}
      exact
      path={item.url}
      element={item.module}
    />
  ))

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    if (user !== null) {
      const sessions = JSON.parse(localStorage.getItem('sessions'))

      if (sessions === null) {
        localStorage.setItem('sessions', JSON.stringify({ [user.id]: { quote: undefined, cart: undefined } }))
      } else {
        const session = sessions[user.id]

        if (session === null || session === undefined) {
          localStorage.setItem('sessions', JSON.stringify({ ...sessions, [user.id]: { quote: undefined, cart: undefined } }))
        }
      }
    }

    const localhost = process.env.REACT_APP_ENVIROMENT === 'prod' ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV
    sessionStorage.setItem('localhost', localhost)
  }, [])

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <Router>
          {token !== null && user !== null && userPermissions !== null ? (
            <NavHeader
              prefersDarkMode={prefersDarkMode}
              setPrefersDarkMode={setPrefersDarkMode}
            >
              <Routes>
                <Route
                  exact
                  path={routes[0].url}
                  element={userPermissions['reports-access'] !== undefined ? <div>Sitio aun en construccion Esperalo pronto</div> : routes[0].module}
                />
                {modules}
                <Route
                  path='*'
                  element={
                    <Navigate
                      replace
                      to={routes[0].url}
                    />
                  }
                />
              </Routes>
            </NavHeader>
          ) : (
            <Routes>
              <Route
                exact
                path='/'
                element={<LogInIndex />}
              />
              <Route
                path='*'
                element={
                  <Navigate
                    replace
                    to='/'
                  />
                }
              />
            </Routes>
          )}
        </Router>
      </ThemeProvider>
    </div>
  )
}
