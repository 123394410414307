import * as React from 'react'
import { Link } from 'react-router-dom'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import { CreditCard, PointOfSale, PersonOutlineOutlined, AccountCircleOutlined, Assignment, Dashboard, ShoppingCartOutlined, ContentPasteOutlined, AssessmentOutlined, HomeWorkOutlined, QrCodeScannerOutlined, LocalShippingOutlined, SupportAgent } from '@mui/icons-material'

import SosIcon from '@mui/icons-material/Sos';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

function setMainListItems() {
  const userPermissions = JSON.parse(sessionStorage.getItem('permissions'))

  return [
    userPermissions !== null &&
      userPermissions['reports-access'] !== undefined && {
        url: '/',
        icon: <Dashboard />,
        title: 'Dashboard'
      },
    {
      url: '/profile',
      icon: <AccountCircleOutlined />,
      title: 'Tu perfil'
    },
    {
      url: '/MyShoppingCart',
      icon: <ShoppingCartOutlined />,
      title: 'Mi Carrito'
    },
    userPermissions !== null &&
      userPermissions['role'] !== 'Cliente' && {
        url: '/cut-offs',
        icon: <PointOfSale />,
        title: 'Mis cortes'
      },
    // userPermissions !== null &&
    //   userPermissions['quotes-access'] !== undefined && {
    //     url: '/quotes',
    //     icon: <PaidOutlined />,
    //     title: 'Cotizaciones'
    //   },
    userPermissions !== null &&
      userPermissions['waybills-access'] !== undefined && {
        url: '/waybillsTable',
        icon: <ContentPasteOutlined />,
        title: 'Guias'
      },
    userPermissions !== null &&
      userPermissions['users-access'] !== undefined && {
        url: '/users',
        icon: <PersonOutlineOutlined />,
        title: 'Usuarios'
      },
    userPermissions !== null &&
      userPermissions['tracking-access'] !== undefined && {
        url: '/tracking',
        icon: <LocalShippingOutlined />,
        title: 'Rastreo'
      },
    userPermissions !== null &&
      userPermissions['sucursal-access'] !== undefined && {
        url: '/addWaybills',
        icon: <QrCodeScannerOutlined />,
        title: 'Escanear Guias'
      },
    userPermissions !== null &&
      userPermissions['address-access'] !== undefined && {
        url: '/adminAddress',
        icon: <HomeWorkOutlined />,
        title: 'Mis direcciones'
      },
    userPermissions !== null &&
      userPermissions['reports-access'] !== undefined && {
        url: '/reports',
        icon: <AssessmentOutlined />,
        title: 'Reportes'
      },
      {
        url: '/support',
        icon: <SosIcon/>,
        title: 'Ayuda Tecnica'
      }
    // {
    //   url: '/prepagos',
    //   icon: <CreditCard />,
    //   title: 'Prepagos'
    // }
  ]
    .filter(item => item)
    .map((item, index) => (
      <Link
        to={item.url}
        key={index}
      >
        <ListItemButton>
          <ListItemIcon title={item.title}>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItemButton>
      </Link>
    ))
}

function setSecondaryListItems() {
  const itemsList = [
    {
      url: '/',
      icon: <Assignment />,
      title: 'CurrentMonth'
    },
    {
      url: '/',
      icon: <Assignment />,
      title: 'Last Quarter'
    },
    {
      url: '/',
      icon: <Assignment />,
      title: 'Year-end sale'
    }
  ]
  return itemsList.map((item, index) => (
    <ListItemButton
      key={index}
      component='a'
      href={item.url}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItemButton>
  ))
}

export const mainListItems = <React.Fragment>{setMainListItems()}</React.Fragment>

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader
      component='div'
      inset
    >
      Saved reports
    </ListSubheader>
    {setSecondaryListItems()}
  </React.Fragment>
)
