import React, { useContext } from 'react'
import { Container, Grid, Paper, Typography, Box, Tabs, Tab, IconButton, Stack, Button, Tooltip } from '@mui/material'
import ShoppingCartContext, { ShoppingCartProvider } from './ShoppingCartContext'
import { Add, ShoppingCartOutlined } from '@mui/icons-material'
import Copyright from '../../General/Copyright'
import Session from './Session'
import InfoCarousel from './InfoCarousel'

function Carts() {
  const { carts, addCart, actualCart, setActualCart } = useContext(ShoppingCartContext)

  return (
    <Container 
      maxWidth='lg'
      sx={{ mt: 4, mb: 4, textAlign: 'left' }}
    >
      <InfoCarousel></InfoCarousel>
      <Grid backgroundColor='default'
        container 
        spacing={1}
      >
        <Grid backgroundColor='default'
          item 
          xs={12}
          md={12}
        >
          <Paper
            variant='outlined'
            sx={{ p: 2, backgroundColor: 'default', minHeight:320 }}
          >
            {/*'Mi carrito'*/}
            <Stack backgroundColor= 'default'
            >
              <Typography
                variant='h1'
                sx={{ paddingY: 2, textAlign: 'left'}}
              >
                Mi carrito
              </Typography>
              {carts.length === 0 ? (
                <Stack
                  backgroundColor= 'default'
                  //spacing={100} //espacio de boton
                  //sx={{ p: 2 }}
                >
                  <Stack
                    backgroundColor= 'default'
                    alignItems='center'
                    direction='column'
                    //spacing={1}
                    //marginTop={2}
                  >
                    <ShoppingCartOutlined sx={{ fontSize: 50 }} />
                    <Stack backgroundColor= 'default'
                    >
                      <Typography variant='subtitle1'>Sin sesiones</Typography>
                      <Typography variant='body1'>Para poder empezar, es necesario que inicies una sesión</Typography>
                    </Stack>
                  </Stack>
                  {/*Boton crear sesion*/}
                  <Stack
                    backgroundColor= 'default'
                    direction='row'
                    justifyContent='end'
                  >
                    <Button
                      variant='contained'
                      onClick={() => addCart()}
                    >
                      Crear sesión
                    </Button>
                  </Stack>
                </Stack>
              ) : (
                <Paper variant='outlined'>
                  <Stack>
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                      sx={{ boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)' }}
                    >
                      <Tabs
                        value={actualCart}
                        onChange={(event, index) => {
                          setActualCart(index)
                          setActualCart(index)
                        }}
                        variant='scrollable'
                        visibleScrollbar={true}
                        scrollButtons={false}
                        allowScrollButtonsMobile
                      >
                        {carts.map((session, index) => (
                          <Tab label={`Sesión ${index + 1}`} />
                        ))}
                      </Tabs>

                      <Tooltip
                        title={carts.length >= 5 ? 'Solo puedes tener cinco sesiones' : 'Agregar sesión'}
                        placement='left'
                        arrow
                      >
                        <Box>
                          <IconButton
                            disabled={carts.length >= 5}
                            onClick={() => addCart()}
                          >
                            <Add />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </Stack>
                    {carts.map((item, index) => {
                      if (index === actualCart) return <Session />
                    })}
                  </Stack>
                </Paper>
              )}
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      <Paper
        sx={{
          p: 1,
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 50,
          width: 'auto'
        }}
      >
        <Copyright sx={{ pt: 4 }} />
      </Paper>
    </Container>
  )
}

export default function MyShoppingCart() {
  return (
    <ShoppingCartProvider>
      <Carts />
    </ShoppingCartProvider>
  )
}
