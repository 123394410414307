import { Stack, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material'
import { MoneyOff, PriceCheck, ShoppingCartCheckoutOutlined, ArrowBack, FileDownloadOutlined } from '@mui/icons-material'
import { MainContainer, SnackBarAlert, SkeletonLoad, SectionCard, downloadTicket } from '../../Shared'
import { useEffect, useState, useCallback } from 'react'
import { fechaConHoraCorta } from '../../../utils/FormatoDeFecha'
import { useLocation, useNavigate } from 'react-router-dom'
import { getFlow } from './Services'
const token = sessionStorage.getItem('token')
const host = sessionStorage.getItem('localhost')

export default function CutOffItems() {
  const { state } = useLocation()
  const { cutOffId } = state
  const navigate = useNavigate()
  const [paid, setPaid] = useState()
  const [unpaid, setUnpaid] = useState()
  const [expenses, setExpenses] = useState()
  const [tickets, setTickets] = useState()
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getFlow({ host, token, id: cutOffId })
      .then(({ paid, unpaid, gastos, tickets, error }) => {
        if (error) setShowError(true)
        else {
          setPaid(paid)
          setUnpaid(unpaid)
          setExpenses(gastos)
          setTickets(tickets)
        }
      })
      .catch(() => setShowError(true))
      .finally(() => setLoading(false))
    return () => {
      setPaid()
      setUnpaid()
      setExpenses()
      setTickets()
    }
  }, [])

  const WaybillsTableRow = useCallback(({ waybill }) => {
    const { created_at, numero, destinoMunicipio, destinoEstado, origenPersona, precio_final, nombre_movimiento, kg, ancho, alto, largo } = waybill
    const date = fechaConHoraCorta(new Date(created_at))

    return (
      <TableRow key={numero}>
        <TableCell>
          <Typography maxWidth={150}>{date}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{numero}</Typography>
        </TableCell>
        <TableCell maxWidth={280}>
          {!origenPersona || !destinoMunicipio || !destinoEstado ? (
            <Typography>No hay información</Typography>
          ) : (
            <>
              <Typography>Origen: {origenPersona}</Typography>
              <Typography>
                Destino: {destinoMunicipio}, {destinoEstado}
              </Typography>
            </>
          )}
        </TableCell>
        <TableCell>
          <Typography>{kg}KG {ancho}x{alto}x{largo}</Typography>
        </TableCell>
        <TableCell>
          <Typography>${precio_final}</Typography>
        </TableCell>
        <TableCell>{nombre_movimiento}</TableCell>
      </TableRow>
    )
  }, [])

  const ExpenseTableRow = useCallback(({ expense }) => {
    const { details, amount } = expense
    return (
      <TableRow key={details}>
        <TableCell>{details}</TableCell>
        <TableCell>${amount}</TableCell>
      </TableRow>
    )
  }, [])

  const TicketTableRow = useCallback(({ ticket }) => {
    const { ticketGeneratedNumber, uuid, ticket_type, pago_pendiente, monto } = ticket

    const DownloadTicketButton = () => { 
      const [downloadingTicket, setDownloadingTicket] = useState(false)

      return (
        <IconButton
          title='Descargar ticket'
          disabled={downloadingTicket}
          onClick={() => downloadTicket(setDownloadingTicket, uuid, [], pago_pendiente)}
          sx={{ color: '#007FFF' }}
        >
          <FileDownloadOutlined sx={{ fontSize: 21.5 }} />
        </IconButton>
      )
    }

    return (
      <TableRow key={uuid}>
        <TableCell>{ticketGeneratedNumber}</TableCell>
        <TableCell>{uuid}</TableCell>
        <TableCell>{ticket_type}</TableCell>
        <TableCell>{pago_pendiente?"pendiente":"pagado"}</TableCell>
        <TableCell>${monto}</TableCell>
        <TableCell>
          <Stack
            direction='row'
            columnGap={0.5}
          >
            <DownloadTicketButton />
          </Stack>
        </TableCell>
      </TableRow>
    )
  }, [])

  return (
    <MainContainer>
      <Stack spacing={2}>
        <Stack
          direction='row'
          alignItems='center'
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>

          <Typography variant='h1'>Historial de flujo</Typography>
        </Stack>
        {loading ? (
          <SkeletonLoad
            types={['rectangular, rounded']}
            numberOfItems={4}
            randomHeight
            heightRange={[30, 70]}
          />
        ) : (
          <>
            {tickets !== undefined && tickets?.length > 0 && (
                <SectionCard
                  title='Tickets'
                  icon={<ShoppingCartCheckoutOutlined sx={{ fontSize: 18 }} />}
                >
                  <CustomTable
                    headers={['Folio', 'UUID', 'Tipo de ticket', 'Pendiente', 'Monto','Acciones']}
                    numberOfItems={tickets?.length ?? 0}
                    data={tickets}
                    row={item => <TicketTableRow ticket={item} />}
                  />
                </SectionCard>
              )}
            {paid !== undefined && (
              <SectionCard
                title='Pagados'
                icon={<PriceCheck sx={{ fontSize: 18 }} />}
              >
                {paid?.waybills?.length > 0 && (
                  <>
                    <Typography
                      variant='h3'
                      marginTop={2}
                      >
                      Guias Generadas
                    </Typography>
                    <CustomTable
                      headers={['Fecha', 'Numero', 'Origen y destino', 'Medidas','Costo', 'Tipo de movimiento']}
                      numberOfItems={paid?.waybills?.length ?? 0}
                      data={paid.waybills}
                      row={item => <WaybillsTableRow waybill={item} />}
                      />
                  </>
                )}
                {paid?.deliveries?.length > 0 && (
                  <>
                    <Typography
                      variant='h3'
                      marginTop={2}
                    >
                      Envios recibidos
                    </Typography>
                    <CustomTable
                      headers={['Fecha', 'Numero', 'Origen y destino', 'Medidas','Costo', 'Tipo de movimiento']}
                      numberOfItems={paid?.deliveries?.length ?? 0}
                      data={paid.deliveries}
                      row={item => <WaybillsTableRow waybill={item} />}
                    />
                  </>
                )}
              </SectionCard>
            )}

            {unpaid !== undefined && unpaid?.length > 0 && (
              <SectionCard
                title='Pago no identificado'
                icon={<MoneyOff sx={{ fontSize: 18 }} />}
              >
                <Typography
                  variant='h3'
                  marginTop={2}
                >
                  Guías
                </Typography>
                <CustomTable
                  headers={['Fecha', 'Numero', 'Origen y destino', 'Costo', 'Tipo de movimiento']}
                  numberOfItems={unpaid?.length ?? 0}
                  data={unpaid}
                  row={item => <WaybillsTableRow waybill={item} />}
                />
              </SectionCard>
            )}
            {expenses !== undefined && expenses?.length > 0 && (
              <SectionCard
                title='Gastos'
                icon={<ShoppingCartCheckoutOutlined sx={{ fontSize: 18 }} />}
              >
                <CustomTable
                  headers={['Detalles', 'Cantidad']}
                  numberOfItems={expenses?.length ?? 0}
                  data={expenses}
                  row={item => <ExpenseTableRow expense={item} />}
                />
              </SectionCard>
            )}
          </>
        )}
      </Stack>
      <SnackBarAlert
        displayState={[showError, setShowError]}
        message='Ocurrió un error al recuperar el flujo, intente más tarde.'
        severity='error'
      />
    </MainContainer>
  )
}

const CustomTable = ({ headers, data, row }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers?.map(item => (
            <TableCell key={item}>{item}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{data?.map(item => row(item))}</TableBody>
    </Table>
  )
}
