import { useState, useEffect } from 'react'
import { Typography, Divider, Button, Stack } from '@mui/material'
import { getFlowTotal } from './Services'
import { useNavigate } from 'react-router-dom'
import AirOutlinedIcon from '@mui/icons-material/AirOutlined'
import SkeletonLoad from '../../Shared/SkeletonLoad'
import { Message, ModalView } from '../../Shared'


export default function ShowFlow({ displayState, cutOff, setShowExpense }) {
  const navigate = useNavigate()
  const token = sessionStorage.getItem('token')
  const host = sessionStorage.getItem('localhost')
  const { cutOffId, status } = cutOff
  const [show, setShow] = displayState
  const [paid, setPaid] = useState()
  const [unpaid, setUnpaid] = useState()
  const [expenses, setExpenses] = useState()
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)

  const handleGetToken = async () => {
    try {
        const envConfig = {
            url: process.env.REACT_APP_ENVIROMENT === 'prod' ? process.env.REACT_APP_URL_PROD : process.env.REACT_APP_URL_DEV
          }
          const response = await fetch(`${envConfig.url}/api/v2/cut-offs/${cutOffId}/pdf`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Access-Control-Allow-Origin': '*'
            }
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'archivo.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    } catch (err) {
        console.error('Error al descargar el PDF:', err);
    }
};
  useEffect(() => {
    if (show) {
      setLoading(true)
      getFlowTotal({ host, token, id: cutOffId })
        .then(({ paid, unpaid, records, error }) => {
          if (!error) {
            setPaid(Number(paid))
            setUnpaid(Number(unpaid))
            setExpenses(Number(records))
          } else setShowError(true)
        })
        .catch(() => setShowError(true))
        .finally(() => setLoading(false))
    }
    return () => {
      setPaid()
      setUnpaid()
      setExpenses()
    }
  }, [show])

  return (
    <ModalView
      displayState={[show, setShow]}
      width={'30%'}
      maxHeight={'70%'}
    >
      <Typography
        variant='h1'
        paddingY={1}
      >
        Total de flujo
      </Typography>
      <Divider sx={{ marginBottom: 1 }} />
      {loading ? (
        <SkeletonLoad
          types={['rectangular, rounded']}
          randomHeight
          heightRange={[20, 50]}
          numberOfItems={4}
        />
      ) : showError ? (
        <Message
          icon={<AirOutlinedIcon sx={{ fontSize: 40 }} />}
          message='No pudimos recuperar el flujo de guías y productos, inténtalo en unos momentos.'
        />
      ) : (
        <Stack spacing={2}>
          <Typography variant='body1'>Pagado - ${paid}</Typography>
          <Typography variant='body1'>Pago no identificado - ${unpaid}</Typography>
          <Typography variant='body1'>Gastos - ${expenses}</Typography>
        </Stack>
      )}
      <Stack
        marginTop={2}
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-end'
      >
        {!showError && !loading && (
          <>
            {status === 'Iniciado' && (
              <Button
                onClick={() => {
                  setShow(false)
                  setShowExpense(true)
                }}
              >
                Crear gasto
              </Button>
            )}
            <Button
              disabled={paid === 0 && unpaid === 0 && expenses === 0}
              onClick={() => navigate('/cut-offs/items', { state: { cutOffId } })}
            >
              Ver artículos
            </Button>
            {status ==="Finalizado" &&(<Button
              disabled={paid === 0 && unpaid === 0 && expenses === 0}
              onClick={handleGetToken}
            >
              Obtener Pdf
            </Button>
            )}
          </>
        )}
        <Button onClick={() => setShow(false)}>Cerrar</Button>
      </Stack>
    </ModalView>
  )
}
